import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Seo, Layout } from "../../components"
import { StaticImage } from "gatsby-plugin-image"
import useDarkMode from "use-dark-mode"
import { ShoppingBagIcon, BriefcaseIcon, DeviceMobileIcon, LockClosedIcon, CurrencyEuroIcon, TrendingUpIcon } from '@heroicons/react/outline'

const transferFeatures = [
  {
    id: 1,
    name: 'Création de site e-commerce',
    description:
      'Créer un site e-commerce permet de vendre vos produits et services en ligne et de toucher une large clientèle, 7 jours sur 7 et 24 heures sur 24.',
    icon: ShoppingBagIcon,
  },
  {
    id: 2,
    name: 'Création de site vitrine',
    description:
      'Créer un site vitrine est indispensable pour tout entrepreneur et entreprise, il permet de mettre en avant votre activité et de faire connaître votre entreprise auprès d\'un plus large public.',
    icon: BriefcaseIcon,
  },

]
const communicationFeatures = [
  {
    id: 1,
    name: 'Site internet responsive',
    description:
      'Un site Internet doit être responsive. Cela signifie que le site doit être adapté à tous les écrans, toutes les tailles d’écran, tous les types d’appareils.',
    icon: DeviceMobileIcon,
  },
  {
    id: 2,
    name: 'Site internet sécurisé',
    description:
      'La sécurité d\'un site Internet est une priorité. Plusieurs solutions sont disponibles et sont mises en place par notre agence web pour protéger votre site Internet.',
    icon: LockClosedIcon,
  },
]


const seoFeatures = [
  {
    id: 1,
    name: 'Votre site Internet en première page',
    description:
      'Faites apparaître votre site Internet en première page de résultats du moteur de recherche Google afin d\'en augmenter le trafic. Notre agence à Paris développe votre référencement local.',
    icon: TrendingUpIcon,
  },
  {
    id: 2,
    name: 'Plus de trafic, plus de chiffre d\'affaires !',
    description:
      'Un bon référencement naturel se traduira par une augmentation du trafic organique (en provenance des moteurs de recherche) et de votre chiffre d\'affaires.',
    icon: CurrencyEuroIcon,
  },
]

const AgenceParis = () => {
  const darkMode = useDarkMode(false, {
    classNameDark: "dark",
    classNameLight: "light",
    storageKey: "darkMode",
  })


  const isDark = darkMode.value
  return (
    <Layout>
      <Seo 
      
      title="Agence web à Paris - Création de site Internet - Koomet" 
      desc="Votre agence web à Paris (75) : création site internet vitrine / e-commerce à Paris, référencement naturel (SEO), maintenance"
      breadcrumb
      bread1name="Accueil"
      bread1url="/"
      bread2name="Paris"
      bread2url="/agence/paris/"
      />
      <div className="container mx-auto px-6 lg:px-0">
        <div className="relative">
          <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
            <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl lg:right-72" />
            <svg
              className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-indigo-100 dark:text-violet/20" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
            </svg>
          </div>
        </div>
        <div className="relative">
          <div className="inset-x-0 bottom-0 h-1/2 bg-gris" />
          <div data-sal="fade" data-sal-duration="800" data-sal-easing="ease" className="max-w-8xl mx-auto lg:px-20">
            <div className="relative shadow-xl rounded-xl sm:overflow-hidden shadow-indigo-100 dark:shadow-md">
              <div className="absolute inset-0">
                <StaticImage
                  src="../../assets/Paris.webp"
                  quality={100}
                  alt="Agence web Paris - Koomet - création de site internet, site vitrine, site e-commerce - référencement naturel (SEO), maintenance" 
                  className="h-full w-full rounded-xl object-cover"
                />
                <div className="absolute inset-0 rounded-xl bg-gradient-to-t bg-violetBright mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                <h1 data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">Agence web à Paris</h1>
                <h2 data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="mt-6 text-xl text-white max-w-5xl">
                  Notre agence web à Paris et en Ile de France vous propose différentes prestations: Création de site Internet, référencement naturel (SEO) Google de votre site Internet, maintenance de site Internet.
                </h2>

              </div>
            </div>
          </div>
        </div>
        <div className="overflow-hidden pt-12">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">

            <div className="relative mt-4 lg:mt-18 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <h3 className="text-2xl font-extrabold text-gray-900 dark:text-gray-200 tracking-tight sm:text-3xl">
                  Création de site Internet à Paris
                </h3>
                <p className="mt-3 text-lg text-gray-500 dark:text-gray-300">
                  Notre agence web à Paris vous accompagne dans la création de votre site web.
                </p>
                <dl className="mt-10 space-y-10">
                  {transferFeatures.map((item) => (
                    <div key={item.id} className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-w-primary text-white">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">{item.name}</p>
                      </dt>
                      <dd className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-300">{item.description}</dd>
                    </div>
                  ))}
                </dl>
                <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div className="flex flex-wrap justify-center text-lg" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease-in-out">
                    <AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/contact/" className="w-full md:mr-3 mt-4 lg:mt-0 md:w-auto hover:-translate-y-0.5 hover:shadow-md py-2 px-6 border-2 border-violet bg-d-primary text-white dark:text-white transition ease-in duration-300 text-center font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg" vers="/contact" modele="li">Obtenir un devis</AniLink>
                    <AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/creation-site-internet/" className="py-2 px-6 border-2 border-white bg-transparent text-violet dark:text-white hover:bg-white hover:text-violet transition  ease-in  duration-300 text-center font-semibold  shadow-md focus:outline-none  focus:ring-2  focus:ring-offset-2  rounded-lg w-full mt-4 lg:mt-0 md:w-auto hover:-translate-y-0.5 hover:shadow-md" vers="/404" modele="li">En savoir plus...</AniLink>
                  </div>
                </div>
              </div>
              <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                <StaticImage
                  src="../../assets/1.png"
                  quality={100}
                  width={600}
                  alt="Création de site vitrine et site e-commerce à Paris"
                  className="relative mx-auto"
                />

              </div>
            </div>
            <div className="relative mt-12 sm:mt-16 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="lg:col-start-2">
                  <h3 className="text-2xl font-extrabold text-gray-900 dark:text-gray-200 tracking-tight sm:text-3xl">Création de site Internet moderne</h3>
                  <p className="mt-3 text-lg text-gray-500 dark:text-gray-300">
                    Il est très important pour un site de fonctionner correctement, que le site soit moderne et soit adapté à tous les navigateurs.
                  </p>

                  <dl className="mt-10 space-y-10">
                    {communicationFeatures.map((item) => (
                      <div key={item.id} className="relative">
                        <dt>
                          <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-w-primary text-white">
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                          </div>
                          <p className="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">{item.name}</p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-300">{item.description}</dd>
                      </div>
                    ))}
                  </dl>
                  <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                    <div className="flex flex-wrap justify-center text-lg" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease-in-out">
                      <AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/contact/" className="w-full md:mr-3 mt-4 lg:mt-0 md:w-auto hover:-translate-y-0.5 hover:shadow-md py-2 px-6 border-2 border-violet bg-d-primary text-white dark:text-white transition ease-in duration-300 text-center font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg" vers="/contact" modele="li">Obtenir un devis</AniLink>
                      <AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/creation-site-internet/" className="py-2 px-6 border-2 border-white bg-transparent text-violet dark:text-white hover:bg-white hover:text-violet transition  ease-in  duration-300 text-center font-semibold  shadow-md focus:outline-none  focus:ring-2  focus:ring-offset-2  rounded-lg w-full mt-4 lg:mt-0 md:w-auto hover:-translate-y-0.5 hover:shadow-md" vers="/404" modele="li">En savoir plus...</AniLink>
                    </div>
                  </div>
                </div>
                <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                  <StaticImage
                    src="../../assets/2.png"
                    quality={100}
                    width={600}
                    alt="Création de site internet moderne responsive et sécurisé à Paris"
                    className="relative mx-auto"
                  />
                </div>
              </div>
            </div>
            <div className="relative mt-12 pb-2 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <h3 className="text-2xl font-extrabold text-gray-900 dark:text-gray-200 tracking-tight sm:text-3xl">
                  Référencement local à Paris & en Ile de France
                </h3>
                <p className="mt-3 text-lg text-gray-500 dark:text-gray-300">
                  Le référencement naturel (SEO) consiste à améliorer le positionnement d'un site Internet sur les moteurs de recherche.
                </p>

                <dl className="mt-10 space-y-10">
                  {seoFeatures.map((item) => (
                    <div key={item.id} className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-w-primary text-white">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">{item.name}</p>
                      </dt>
                      <dd className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-300">{item.description}</dd>
                    </div>
                  ))}
                </dl>
                <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div className="flex flex-wrap justify-center text-lg" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease-in-out">
                    <AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/contact/" className="w-full md:mr-3 mt-4 lg:mt-0 md:w-auto hover:-translate-y-0.5 hover:shadow-md py-2 px-6 border-2 border-violet bg-d-primary text-white dark:text-white transition ease-in duration-300 text-center font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg" vers="/contact" modele="li">Obtenir un devis</AniLink>
                    <AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/referencement-naturel-site-internet/" className="py-2 px-6 border-2 border-white bg-transparent text-violet dark:text-white hover:bg-white hover:text-violet transition  ease-in  duration-300 text-center font-semibold  shadow-md focus:outline-none  focus:ring-2  focus:ring-offset-2  rounded-lg w-full mt-4 lg:mt-0 md:w-auto hover:-translate-y-0.5 hover:shadow-md" vers="/404" modele="li">En savoir plus...</AniLink>
                  </div>
                </div>
              </div>
              <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                <StaticImage
                  src="../../assets/3.png"
                  quality={100}
                  width={600}
                  alt="Agence référencement naturel et local à Paris"
                  className="relative mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease-in-out" className="relative pt-16 overflow-hidden sm:pt-24 lg:pt-32 z-0">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <h4 className="text-base font-semibold tracking-wider text-violet uppercase">Suivi de projet</h4>
            <p className="mt-2 text-3xl font-extrabold text-gray-900 dark:text-gray-200 tracking-tight sm:text-4xl">
              Espace de gestion de projet
            </p>
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500 dark:text-gray-300">
              Un suivi de projet efficace grâce à un espace de gestion de projet en ligne. Vous pourrez y gérer votre projet en temps réel, suivre l'avancement de votre projet, y retrouver vos factures et devis...
            </p>
          </div>
          <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-20">
            {isDark ? (
              <StaticImage
                src="../../assets/GestionProjetD.jpg"
                quality={100}
                alt="Création site Internet, gestion de projet web à Paris."
                className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 "
              />
            ) : (
              <StaticImage
                src="../../assets/GestionProjetW.jpg"
                quality={100}
                alt="Création site Internet, gestion de projet web à Paris."
                className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              />
            )}
          </div>
        </div>
      </div>
      <div className="group">
        <div className="relative bg-gradient-to-tr from-indigo-700 via-violet to-indigo-500 animation">
          <div className="max-w-3xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
            <h5 className="text-3xl font-extrabold text-white sm:text-4xl">
              <span className="block">Nous aimerions travailler avec vous !</span>
            </h5>
            <p className="mt-4 text-2xl font-medium leading-6 text-indigo-100">
              Comment pouvons-nous vous aider ?
            </p>
            <AniLink
              cover bg="#6377E9" direction="up" duration={0.6} to="/contact/"
              className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-violet bg-white hover:bg-indigo-50 sm:w-auto transition duration-200 hover:-translate-y-0.5 hover:shadow-md"
            >
              Contactez notre agence de Paris
            </AniLink>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AgenceParis
